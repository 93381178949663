body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo-box {
  margin-top: 23%;
}

.logo {
  height: 20%;
  width: 20%;
  display: block;
  margin: auto;
}

.sublogo {
  text-align: center;
  font-size: 1.2em;
}

.sitemap {
  position: absolute;
  bottom: 0;
  margin: 1%;
}
